* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Noto Sans KR", "Noto Sans CJK KR", "Roboto" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* prevent drag */
  cursor: default;
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  padding-left: 0;
}
